@import "./init";
@import "../../node_modules/modern-normalize/modern-normalize.css";

html {
  font-family: $system-fonts-list;
}

.custom-scrollbar {
  @media only screen and (min-width: 768) {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #34373a;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #9a9a9a;
      border-radius: 100px;
    }
  }
}

body {
  font: $fw-base #{$fs-base}/#{$lh-base} $regular-font;
  background-color: $background;
  background: linear-gradient(
    90deg,
    rgba(80, 34, 81, 1) 0%,
    rgba(111, 58, 100, 1) 35%,
    rgba(58, 44, 148, 1) 100%
  );
  background-size: cover;
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  color: $white;
  font-weight: normal;
  margin: 0 auto;
  scroll-behavior: smooth;
  padding: 0;
  @extend .custom-scrollbar;

  &.isMobMenuOpen {
    overflow: hidden;
  }
}

p {
  line-height: 120%;
  margin: 0;
}

button {
  @include transition();
  margin: 0;
  border: 0;
  background: transparent;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: none;
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
  }
}

a {
  @include transition();
  text-decoration: none;
  color: $secondary-text;
  outline: none;

  &:hover {
    color: $white;
  }
}

.btn {
  @include font(400, 20px, 120%, $subheader-font);
  @include size(100%, 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: $background-2;
  background-color: $secondary-text;
  text-transform: uppercase;
  border-radius: $br-default;

  @include tablets {
    max-width: 340px;
    margin: 0 auto;
  }

  &--link {
    height: 8vh;
    background-color: rgba($color: $primary-text, $alpha: 0.8);

    img {
      @include size(28px);
      margin-right: $gap2;
    }

    &:hover {
      color: $background;
      background-color: rgba($color: $primary-text, $alpha: 1);
    }

    @include phones-xs {
      @include font-size(14px, 100%);

      img {
        @include size(20px);
      }
    }
  }
}

h1 {
  @include font($fw-base, 34px, 120%, $header-font);
  margin: 0;
  color: $secondary-text;

  @include tablets {
    @include font($fw-base, 24px, 30px);
  }
}

h2 {
  @include font($fw-base, 30px, 120%, $header-font);
  margin: 0;
  color: $secondary-text;

  @include tablets {
    @include font($fw-base, 24px, 30px);
  }
}

.header {
  position: sticky;
  top: 0;
  @include size(100%, $header-height-mob);
  background-color: rgba($color: $background, $alpha: 0.7);
  backdrop-filter: brightness(50%);
  z-index: 99;

  & > .block__wrap {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &.isMobMenuOpen {
    background-color: transparent;
    backdrop-filter: brightness(20%);
  }

  &__logo {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 7px 0px rgba(106, 202, 235, 0.5);

    img {
      @include size(100%);
      object-fit: cover;
    }
  }

  &__slogan {
    margin-left: $gap3;

    & > h1 {
      @include font(normal, 24px, 31px, $header-font);
      color: $secondary-text;
      text-shadow: 0px 0px 2px $secondary-text;
      margin: 0;
    }

    span {
      color: $white;
      display: none;

      @include desktops {
        display: inline;
        margin-left: 16px;
      }
    }
  }

  &__btn {
    @include size(24px, 16px);
    display: grid;
    gap: 6px 0;
    margin-left: auto;
    position: relative;

    @include tablets-lg {
      margin-left: 0;
    }

    span {
      @include size(100%, 2px);
      @include transition();
      background: $secondary-text;
    }

    &.isMobMenuOpen {
      span {
        @include absolute();

        &:nth-child(1) {
          transform: rotate(-45deg);
        }

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          transform: rotate(45deg);
        }
      }
    }
  }

  &__tel {
    color: $white;
    font-size: 16px;
    display: none;

    p {
      line-height: 100%;
    }

    @include tablets-lg {
      display: flex;
      gap: 0 20px;
      margin: auto 30px auto auto;
    }
  }
}

.mobile-menu {
  @include size(100%, 0);
  @include absolute($header-height-mob, 0, auto, 0);
  @include transition();
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  overflow: hidden;
  padding: 0 25px;
  z-index: 99;

  @include desktops {
    width: 36%;
    left: auto;
  }

  &.isMobMenuOpen {
    height: 100%;
    backdrop-filter: brightness(10%);
    overflow: auto;
  }

  &__item {
    @include size(100%, 45px);
    @include font(normal, 22px, 100%);
    display: flex;
    border-bottom: 1px solid $secondary-text;
    text-align: center;
    color: $white;
    text-transform: uppercase;

    span {
      margin: auto;
    }

    &:hover {
      color: $secondary-text;
    }
  }
}

main {
  @extend .custom-scrollbar;
}

.block {
  padding: 30px 0;
  border-bottom: 2px solid $secondary;

  &__wrap {
    padding: 0 16px;
    max-width: 1440px;
    margin: 0 auto;

    @include tablets {
      padding: 0 24px;
    }

    @include desktops {
      padding: 0 40px;
    }
  }

  &__header {
    @include font($fw-base, 30px, 120%, $header-font);
    margin: 0 auto 20px auto;
    color: $secondary-text;
    text-align: center;
    text-shadow: 1px 1px 5px rgba($color: $black, $alpha: 0.5);
    max-width: 1440px;
    padding: 0 16px;

    @include tablets {
      font-size: 32px;
      padding: 0 24px;
    }

    @include tablets-lg {
      font-size: 34px;
      padding: 0 40px;
    }

    @include desktops {
      font-size: 40px;
      text-align: left;
    }
  }

  &__info {
    font-size: 18px;
    text-align: center;
    margin: 16px auto;
    color: $white;
    max-width: 1440px;

    @include desktops {
      font-size: 20px;
      display: flex;
      padding: 0 40px;
      gap: 0 14px;
    }
  }

  &__text {
    font-size: 20px;
    color: $primary-text;
    margin: 20px auto;

    @include desktops {
      font-size: 22px;
    }

    i,
    span {
      color: $white;
      font-style: normal;
    }
  }
}

.hero {
  position: relative;
  border-bottom: 0;
  z-index: 1;
  padding: 20px 0 0;

  @include desktops {
    padding: 0;

    .block__header {
      display: none;
    }
  }

  &-slider {
    @include size(100%, 270px);
    max-width: 1360px;
    margin: 0 auto;
    overflow: hidden;

    @include tablets {
      height: 390px;
    }

    @include desktops {
      height: 430px;
    }

    &__block {
      @include size(360px, 270px);
      position: relative;

      @include tablets {
        height: 390px;
      }

      @include desktops {
        height: 430px;
      }

      &-title {
        @include size(230px, 36px);
        @include absolute(0, auto, auto, 0);
        display: flex;
        background: url("../img/hero-slider/hero-slider-title.png") top right
          no-repeat;
        z-index: 2;

        @include tablets-lg {
          @include size(320px, 60px);
          background-size: cover;
        }
      }

      h3 {
        font-size: 20px;
        color: $background-2;
        margin: auto;
        width: 100%;
        text-align: center;
        padding-right: 26px;

        @include tablets-lg {
          font-size: 26px;
          padding-right: 36px;
        }
      }
    }

    &__img {
      @include size(100%);
      object-fit: cover;
    }

    &__btn {
      @include absolute(auto, 20px, 20px, auto);
      @include size(40px);
      @include transition();
      border: 1px solid $primary-text;
      background-color: rgba($color: $primary-text, $alpha: 0.1);
      border-radius: 50%;
      z-index: 2;

      &::after {
        @include absolute();
        @include size(18px, 14px);
        content: "";
        background: url("../img/hero-slider/hero-slider-arrow.png") center
          no-repeat;
        background-size: cover;

        @include tablets-lg {
          @include size(28px, 21px);
        }
      }

      @include tablets-lg {
        @include size(60px);
      }
    }
  }
}

.vr-slider {
  @include size(100%, 432px);
  max-width: 1360px;
  margin: 16px auto 30px;
  overflow: hidden;

  @include tablets {
    overflow: visible;
  }

  @include desktops {
    .slick-arrow {
      @include size(40px, 100%);

      &::before {
        font-size: 32px;
        color: $secondary-text;
      }
    }

    .slick-prev {
      left: -40px;
    }

    .slick-next {
      right: -40px;
    }
  }

  &-block {
    @include size(330px, 432px);
    position: relative;
    background-color: $background-2;
    color: $primary-text;
    margin-right: 12px;

    &::before {
      @include size(90px, 40px);
      content: "";
      position: absolute;
      top: 215px;
      right: 0;
      background: url("../img/vr-slide-img.png") center no-repeat;
      background-size: cover;
      z-index: 2;
    }

    &__wrap {
      padding: 0 16px;
    }

    &__title {
      @include font($fw-base, 24px);
      margin: 30px 0 20px;
      color: $white;
      text-transform: uppercase;
      white-space: nowrap;
    }

    &__img {
      @include size(100%, 190px);
      object-fit: cover;
    }

    &__text {
      @include font-size(18px, 120%);
      @include text-cut(6);
      margin-top: 20px;
    }

    &__players {
      position: absolute;
      top: 147px;
      left: 14px;
      display: flex;
      gap: 0 4px;
      opacity: 0.7;
    }

    &__icon {
      @include size(16px, 30px);
      display: inline-block;
      background: url("../img/icons/icon-person.png") center no-repeat;
      background-size: cover;
    }

    &__line {
      @include size(auto, 1px);
      @include absolute(auto, 16px, 20px, 16px);
      background-color: rgba($color: $white, $alpha: 0.3);
    }
  }
}

.quest {
  &__top {
    @include size(100%, 200px);
    background: url("../img/hero-slider/quest-room.jpg") top center no-repeat;
    background-size: cover;
    text-align: right;
    padding: 0 16px;

    @include tablets {
      height: 270px;
      padding: 0 24px;
    }

    @include desktops {
      max-width: 1360px;
      padding: 0 40px;
      margin: 0 auto;
    }

    h2 {
      @include font($fw-base, 30px, 120%, $header-font);
      color: $primary;
      padding-top: 106px;
      text-shadow: 0 0 10px rgba($color: $white, $alpha: 0.15);

      @include tablets {
        font-size: 36px;
        padding-top: 154px;
      }

      @include desktops {
        font-size: 46px;
        padding-top: 140px;
      }
    }

    p {
      @include font-size(20px, 100%);
      color: $secondary-text;
      margin-top: 16px;

      @include tablets {
        font-size: 24px;
      }

      @include desktops {
        font-size: 28px;
      }
    }
  }

  &__text {
    @include font-size(20px, 120%);
    padding: 26px 0;
    color: $primary-text;
    text-align: center;
  }
}

.birth {
  &__img {
    @include size(100%, 200px);
    display: block;
    margin: 0 auto;
    object-position: top;
    object-fit: cover;

    @include tablets {
      height: 270px;
    }

    @include desktops {
      display: none;
    }

    &--desktop {
      display: none;

      @include desktops {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__text {
    font-size: 22px;
    color: $primary-text;
    margin-top: 16px;
    text-align: center;

    @include desktops {
      text-align: left;
    }

    span {
      color: $white;
    }
  }

  &-collapse {
    margin: 30px auto 0;

    @include desktops {
      margin: 0;
    }

    &__title {
      position: relative;
      font-size: 14px;
      color: $primary-text;
      padding: 0 32px;
      text-align: center;
      text-transform: uppercase;

      @include tablets {
        font-size: 18px;

        &::before,
        &::after {
          bottom: auto;
        }
      }

      @include desktops {
        padding: 0;
      }

      & > span {
        color: $white;
      }
    }

    &__wrap {
      margin: 30px auto 18px;
      display: flex;
      flex-direction: column;
      max-width: 480px;

      @include desktops {
        margin: 30px auto 18px 0;
      }
    }

    &__block {
      @include size(240px, 100px);
      position: relative;
      border: 1px solid $secondary-text;
      background-color: $background-2;
      display: flex;
      align-items: center;
      padding: 0 20px;
      text-transform: uppercase;
      margin-bottom: 19px;

      &:nth-child(even) {
        margin-left: auto;

        &::before {
          @include absolute(45px, auto, auto, -42px);
          @include size(42px, 75px);
          content: "";
          background: url("../img/birt-block-before.png") center no-repeat;
          background-size: contain;
        }
      }

      &:nth-child(odd) {
        &::after {
          @include absolute(45px, -42px, auto, auto);
          @include size(42px, 75px);
          content: "";
          background: url("../img/birt-block-after.png") center no-repeat;
          background-size: contain;
        }
      }

      &:last-child {
        margin-bottom: 0;

        &::after {
          content: none;
        }
      }
    }

    &__notice {
      @include absolute(105%, auto, auto);
      text-transform: lowercase;
      color: $primary-text;
    }

    &__btn {
      margin-top: 40px;

      @include desktops {
        .btn {
          margin: 0 auto 0 0;
        }
      }
    }
  }

  &__bottom {
    @include desktops {
      display: grid;
      grid-template-columns: 1fr max-content;
      gap: 0 60px;
      margin-top: 30px;
    }
  }
}

.service {
  &-slider {
    margin: 25px auto 45px;

    @include tablets-lg {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0 20px;
      padding: 0 24px;
    }

    @include desktops {
      max-width: 1360px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0 40px;
      padding: 0;
    }

    &-block {
      @include size(330px, 430px);
      background-color: $background-2;
      padding: 20px 16px;
      margin-right: 12px;
      position: relative;

      @include tablets-lg {
        @include size(100%, auto);
        margin-right: 0;
        padding: 0 0 12px;
      }

      &__title {
        @include font($fw-base, 28px, 100%, $subheader-font);
        margin: 0 0 20px 0;

        @include tablets-lg {
          @include absolute(16px, 20px, auto, auto);
          @include font($fw-semi, 30px, 100%, $subheader-font);
        }
      }

      &__img {
        @include size(100%, 180px);
        object-fit: cover;
      }

      &__text {
        font-size: 18px;
        margin-top: 20px;
        color: $primary-text;

        @include tablets-lg {
          padding: 0 16px;
        }

        & > p {
          margin-bottom: 8px;
        }

        & > ul {
          padding-left: 16px;
          margin: 0;

          li {
            font-size: 18px;
            color: $primary-text;
          }
        }
      }

      &__price {
        font-size: 18px;
        color: $white;
        margin: 16px 0 0;
      }
    }
  }
}

.steps {
  padding: 0 16px;
  margin: 30px auto;
  max-width: 500px;

  @include tablets {
    max-width: 550px;
    padding: 0;
  }

  @include desktops {
    max-width: 1440px;
    padding: 0 40px;
    margin: 60px auto 30px;
    background: url("../img/steps-desktop-bg.png") 80% center no-repeat;
    background-size: contain;
  }

  &__title {
    font-size: 18px;
    color: $white;
    margin: 0 0 30px;
    text-align: center;

    @include desktops {
      font-size: 22px;
      text-align: left;
      margin: 0 0 40px;
    }
  }

  &-block {
    position: relative;
    display: flex;
    align-items: center;
    color: $primary-text;
    margin-bottom: 24px;

    &::after {
      @include absolute(100%, 0, auto, 0);
      @include size(12px, 22px);
      content: "";
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUCAYAAAC58NwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADDSURBVHgB7VI9DoIwFH59qe4ODE6ewUXDpkdhrZMnEG5QF2X0JrKKh6AzxIQD2FZqAkJ8BN0c/JImL+/7ea9NAQiItLDuUBzCl/gbfsPAxPW2A2vCj9QWQhxz3FeVGhZbdVh6Ecr5pLQWt8PhGD5XqhubtDhXv21FiyE5Lry1q5tLaw0R9KXre1DXjSH2vYQxkIT8FPtT9WZwGHHuppQvrVVG687kjsE9ADCUreVlO70XIs0zcckziuNU02gWMGQzinsAcbxB4LAnBwcAAAAASUVORK5CYII=")
        center no-repeat;

      @include desktops {
        content: none;
      }
    }

    &__num {
      @include size(50px, 50px);
      @include font(normal, 22px, 100%, $subheader-font);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $secondary-text;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 16px;
    }

    p {
      @include font-size(20px, 100%);
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

.kitchen {
  &__wrap {
    display: grid;
    gap: 16px 0;
    margin: 30px auto 20px;
  }

  &-block {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content 1fr;
    gap: 6px 0;
    font-size: 20px;
    position: relative;
    padding-bottom: 16px;

    &::after {
      @include absolute(100%, 0, auto, 0);
      @include size(50%, 1px);
      content: "";
      background-color: $secondary-text;
      opacity: 0.5;

      @include tablets {
        width: 75%;
      }

      @include desktops {
        width: 100%;
      }
    }

    &:last-child {
      &::after {
        content: none;
      }
    }

    &__info {
      font-size: 10px;
      color: $primary-text;
    }
  }
}

.board {
  &__info {
    color: $primary-text;
    padding: 0 16px;
  }

  &__wrap {
    display: grid;
    gap: 30px 0;
    margin: 30px auto;
    max-width: 480px;

    @include tablets-lg {
      max-width: 1440px;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0 20px;
      padding: 0 24px;
      display: grid;
    }

    @include desktops {
      padding: 0 40px;
    }
  }

  &-block {
    @include size(100%, auto);
    display: flex;
    flex-direction: column;
    background-color: $background-2;
    padding: 20px 10px;
    color: $primary-text;

    &__title {
      @include font($fw-base, 28px, 100%, $subheader-font);
      margin: 0;
      color: $white;
    }

    &__img {
      @include size(100%, 180px);
      object-fit: cover;
      margin: 20px auto;

      @include desktops {
        height: 220px;
      }
    }

    &__info {
      font-size: 18px;
      margin-bottom: 20px;
    }

    &__links {
      display: flex;
      justify-content: space-between;
      margin-top: auto;

      & > a {
        font-size: 18px;
        display: inline-block;
        color: $secondary-text;
      }
    }
  }
}

.sertif {
  &__text {
    font-size: 20px;
    color: $primary-text;
  }

  &-img {
    @include size(100%, 270px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: url("../img/hero-slider/sertif-mob.jpg") center no-repeat;
    background-size: cover;
    margin: 30px auto;
    padding: 16px;

    @include tablets-lg {
      padding: 24px;
      background: url("../img/hero-slider/sertif.jpg") center no-repeat;
    }

    @include desktops {
      max-width: 1360px;
    }

    &__title {
      margin: 0 0 0 auto;
      text-align: center;
      color: $primary-text;
      text-shadow: 0 0 5px $black;

      p {
        @include font(normal, 20px, 100%, $header-font);

        &:nth-child(1) {
          font-size: 18px;
          margin-bottom: 4px;

          @include tablets-lg {
            font-size: 22px;
          }
        }

        &:nth-child(2) {
          font-size: 24px;

          @include tablets-lg {
            font-size: 30px;
          }
        }

        @include tablets-lg {
          font-size: 24px;
        }
      }
    }

    &__info {
      margin: 0 auto 0 0;
      text-align: center;
      color: $background-2;
      text-shadow: 2px 2px 4px rgba($color: $black, $alpha: 0.25);

      @include tablets {
        color: $primary-text;
      }

      p {
        @include font(normal, 16px, 100%, $header-font);
        text-transform: uppercase;

        &:nth-child(1) {
          font-size: 22px;
          margin-bottom: 4px;

          @include tablets-lg {
            font-size: 30px;
          }
        }

        @include tablets-lg {
          font-size: 22px;
        }
      }
    }
  }

  &__bottom {
    p {
      font-size: 22px;
      text-align: center;
      margin-bottom: 38px;
      text-transform: uppercase;
    }
  }
}

.socials {
  display: flex;
  justify-content: center;
  gap: 0 18px;
  margin: 40px auto;

  @include desktops {
    justify-content: flex-start;
    margin: 40px 0 0;
    gap: 0 30px;
  }

  &__item {
    @include size(36px);
    cursor: pointer;
    outline: none;

    img {
      @include size(100%);
      object-fit: cover;
    }

    @include tablets-lg {
      @include size(44px);
      gap: 0 22px;
    }

    @include desktops {
      @include size(60px);
      gap: 0 30px;
    }
  }
}

.comments {
  &-slider {
    max-width: 1360px;
    margin: 30px auto;
    padding-bottom: 40px;

    &-block {
      @include size(300px, 210px);
      background-color: $background-2;
      margin-right: 16px;
      padding: 16px;
      border: 1px solid $secondary;

      &__top {
        display: flex;
        align-items: center;

        & > {
          img {
            @include size(60px);
            margin-right: 14px;
          }
        }
      }

      &__stars {
        @include size(90px, 15px);
        display: flex;
        gap: 0 4px;
        margin-top: 3px;
        background: url("../img/icons/star.svg") left repeat-x;
      }

      &__title {
        @include font(normal, 20px, 120%, $header-font);
        width: 190px;
        color: $primary-text;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__text {
        @include text-cut(5);
        margin-top: 25px;
      }
    }

    &__btn {
      @include absolute(auto, 0, 0, 0);
      @include size(60px, 20px);
      background-size: contain;
      background-repeat: no-repeat;

      &--prev {
        left: 65px;
        right: auto;
        background-image: url("../img/icons/big-arrow-left.png");
      }

      &--next {
        right: 65px;
        left: auto;
        background-image: url("../img/icons/big-arrow-right.png");
      }
    }

    &__link {
      display: block;
      width: 100%;
      font-size: 18px;
      text-decoration: underline;
      text-align: center;
    }
  }
}

.about {
  padding: 30px 0;
  color: $primary-text;

  &__text {
    font-size: 18px;
  }
}

.win {
  margin-top: 30px;

  &__wrap {
    display: grid;
    gap: 40px 0;

    @include tablets {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0 40px;
    }
  }

  &-block {
    &__img {
      @include size(80px);
      display: flex;
      margin: 0 auto 18px;

      & > img {
        @include size(100%);
        object-fit: contain;
      }
    }

    &__text {
      max-width: 255px;
      font-size: 20px;
      color: $white;
      text-align: center;
      margin: 0 auto;

      @include tablets {
        font-size: 18px;
      }
    }
  }
}

.contacts {
  &__wrap {
    max-width: 1440px;
    margin: 0 auto;

    @include desktops {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  &-main {
    @include desktops {
      padding-top: 22px;
    }
  }

  &__tel {
    @include font(normal, 30px, 120%, $header-font);
    color: $primary-text;
    display: grid;
    gap: 30px 0;
    text-align: center;
    margin: 30px auto;

    @include tablets-lg {
      grid-template-columns: 1fr 1fr;
      gap: 0 50px;
      max-width: 666px;
    }

    @include desktops {
      font-size: 44px;
      grid-template-columns: auto;
      grid-template-rows: 1fr 1fr;
      gap: 50px 0;
      text-align: left;
    }
  }

  &-adress {
    color: $primary-text;

    @include desktops {
      max-width: 635px;
      margin: 0;
    }

    &__title {
      font-size: 28px;
    }

    &__map {
      margin: 38px auto 30px;
    }

    & > p {
      font-size: 20px;
    }
  }

  &__time {
    margin-top: 10px;
    color: $white;
  }
}

.call {
  @include size(40px);
  @include transition();
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  opacity: 0.75;

  &__icon {
    @include size(100%);
    object-fit: contain;
  }

  &:hover {
    opacity: 1;
  }
}

footer {
  padding: 18px 0;
  text-align: center;
  color: $primary;
}
