@font-face {
  font-family: "FranckerCYR-CondensedReg";
  src: local("FranckerCYR-CondensedReg"),
    url("../fonts/FranckerCYR-CondensedReg.woff2") format("woff2"),
    url("../fonts/FranckerCYR-CondensedReg.woff") format("woff");
}

@font-face {
  font-family: "FranckerCYR-CondensedSmbold";
  src: local("FranckerCYR-CondensedSmbold"),
    url("../fonts/FranckerCYR-CondensedSmbold.woff2") format("woff2"),
    url("../fonts/FranckerCYR-CondensedSmbold.woff") format("woff");
}

@font-face {
  font-family: "AldrichRUSbyDaymarius";
  src: local("AldrichRUSbyDaymarius"),
    url("../fonts/aldrichrusbydaymarius.woff2") format("woff2"),
    url("../fonts/aldrichrusbydaymarius.woff") format("woff");
}
