@import "../mixins/mixins";
@import "fonts";

// header
$header-height-mob: 64px;
$header-height: 120px;

// border-radius
$br-default: 4px;

// Colors
$white: #fff;
$black: #000;
$primary: #BF8C79;
$secondary: #575256;
$secondary2: #FF00A0;
$primary-text: #FACAE9;
$secondary-text: #6ACAEB;
$background: #391056;
$background-2: #332682;

// Fonts
$regular-font: "AldrichRUSbyDaymarius", sans-serif;
$subheader-font: "FranckerCYR-CondensedReg", sans-serif;
$header-font: "FranckerCYR-CondensedSmbold", sans-serif;
$system-fonts-list: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";

// Font Weight
$fw-base: 400;
$fw-semi: 600;

// Line Height
$lh-base: 120%;
$lh-headings: 125%;

// Font size
$fs-base: 14px !default;

// Paddings and margins
$gap1: 4px;
$gap2: 8px;
$gap3: 16px;
$gap4: 24px;
